import React from 'react';

function TermsBlack() {
  return (
    <div>
      <h1>Όροι και Προϋποθέσεις</h1>
      <h2>1. Αποδοχή Όρων</h2>
      <p>
        Εσείς ("Χρήστης" ή "εσείς") και εμείς ("Εταιρεία", "εμείς", "εμάς", ή "δικό μας") δεσμευόμαστε από αυτούς τους
        όρους και προϋποθέσεις ("Συμφωνία"), οι οποίοι διέπουν τη χρήση της ιστοσελίδας και των υπηρεσιών μας. Με την
        πρόσβαση ή χρήση της ιστοσελίδας, συμφωνείτε να δεσμευτείτε από αυτή τη Συμφωνία, δηλώνοντας ότι την έχετε διαβάσει
        και κατανοήσει.
      </p>
      <h2>2. Υποχρεώσεις Χρήστη</h2>
      <p>
        Με τη χρήση της ιστοσελίδας και των υπηρεσιών της, συμφωνείτε να συμμορφώνεστε με όλους τους σχετικούς νόμους και
        κανονισμούς. Είστε πλήρως υπεύθυνοι για οποιοδήποτε περιεχόμενο ανεβάζετε ή αποστέλλετε μέσω της ιστοσελίδας και
        δεν επιτρέπεται να ενεργείτε με παράνομο, δυσφημιστικό ή καταχρηστικό τρόπο κατά τη χρήση των υπηρεσιών μας.
      </p>
      <h2>3. Πνευματική Ιδιοκτησία</h2>
      <p>
        Η Εταιρεία είναι ο κάτοχος της ιστοσελίδας και όλων των πρωτότυπων χαρακτηριστικών, περιεχομένου και
        λειτουργικότητας. Αυτά προστατεύονται από διεθνείς νόμους περί πνευματικής ιδιοκτησίας, εμπορικών σημάτων,
        πατεντών, εμπορικών μυστικών και άλλους νόμους πνευματικής ιδιοκτησίας.
      </p>
      <h2>4. Περιεχόμενο Τρίτων</h2>
      <p>
        Η ιστοσελίδα μπορεί να περιέχει συνδέσμους προς εξωτερικούς ιστότοπους, υπηρεσίες ή πόρους που δεν ανήκουν ή
        ελέγχονται από την Εταιρεία. Δεν φέρουμε ευθύνη για το περιεχόμενο, τις πολιτικές απορρήτου ή τις πρακτικές
        οποιουδήποτε τρίτου μέρους. Κατανοείτε και συμφωνείτε ότι η Εταιρεία δεν θα φέρει καμία ευθύνη για οποιαδήποτε
        ζημία ή απώλεια που προκύπτει από ή σχετίζεται με τη χρήση περιεχομένου τρίτων.
      </p>
      <h2>5. Περιορισμός Ευθύνης</h2>
      <p>
        Η Εταιρεία, οι διευθυντές, οι υπάλληλοι, οι συνεργάτες, οι πράκτορες, οι προμηθευτές ή οι συνεργάτες της δεν
        φέρουν ευθύνη για οποιαδήποτε αποθετική, ειδική, τυχαία ή έμμεση ζημία, συμπεριλαμβανομένων, χωρίς περιορισμό,
        απώλειας καλής θέλησης, χρήσης ή κερδών, ή άλλων άυλων απωλειών που προκύπτουν από (i) την πρόσβαση, χρήση ή
        αδυναμία χρήσης της ιστοσελίδας ή των υπηρεσιών, (ii) οποιαδήποτε συμπεριφορά ή περιεχόμενο τρίτων, ή (iii)
        οποιαδήποτε παράνομη πρόσβαση, χρήση ή τροποποίηση επικοινωνιών ή περιεχομένου σας.
      </p>
      <h2>6. Τερματισμός</h2>
      <p>
        Για οποιονδήποτε λόγο, συμπεριλαμβανομένης της παραβίασης αυτής της Συμφωνίας, μπορούμε να τερματίσουμε ή να
        αναστείλουμε άμεσα την πρόσβασή σας στην ιστοσελίδα και τις υπηρεσίες, χωρίς προηγούμενη ειδοποίηση ή ευθύνη. Με
        τον τερματισμό, η δυνατότητά σας να χρησιμοποιείτε την ιστοσελίδα και τις υπηρεσίες θα λήξει αμέσως.
      </p>
      <h2>7. Εφαρμοστέο Δίκαιο</h2>
      <p>
        Αυτή η Συμφωνία διέπεται και ερμηνεύεται σύμφωνα με τους νόμους της [Δικαιοδοσίας]. Οποιαδήποτε διαφορά ή νομική
        διαδικασία που προκύπτει από ή σχετίζεται με αυτή τη Συμφωνία υπάγεται αποκλειστικά στη δικαιοδοσία των δικαστηρίων
        της [Δικαιοδοσίας].
      </p>
      <h2>8. Τροποποιήσεις</h2>
      <p>
        Διατηρούμε το δικαίωμα να τροποποιήσουμε ή να αλλάξουμε αυτή τη Συμφωνία ανά πάσα στιγμή, κατά την απόλυτη κρίση
        μας. Η πιο πρόσφατη έκδοση της Συμφωνίας θα είναι πάντα διαθέσιμη στην ιστοσελίδα. Συμφωνείτε με την ενημερωμένη
        Συμφωνία εάν συνεχίσετε να χρησιμοποιείτε την ιστοσελίδα και τις υπηρεσίες μετά από οποιαδήποτε τέτοια αλλαγή.
      </p>
      <h2>9. Δικαιότητα</h2>
      <p>
        Εάν οποιαδήποτε διάταξη αυτής της Συμφωνίας θεωρηθεί άκυρη ή μη εκτελεστή, οι υπόλοιποι όροι θα παραμείνουν σε
        πλήρη ισχύ και ισχύ. Μια εκτελεστή διάταξη θα αντικαταστήσει την άκυρη που, στον μέγιστο δυνατό βαθμό, αντικατοπτρίζει
        την πρόθεσή μας.
      </p>
      <h2>10. Ολόκληρη Συμφωνία</h2>
      <p>
        Αυτή η Συμφωνία αντικαθιστά όλες τις προηγούμενες και ταυτόχρονες συμφωνίες και αποτελεί ολόκληρη τη Συμφωνία
        μεταξύ εσάς και της Εταιρείας σχετικά με τη χρήση της ιστοσελίδας και των υπηρεσιών.
      </p>
    </div>
  );
}

export default TermsBlack;
