import React from 'react';

function PrivacyBlack() {
  return (
    <div>
      <h1>Πολιτική Απορρήτου</h1>
      <p>
        Είμαστε αφοσιωμένοι στη διατήρηση της ασφάλειας και της ιδιωτικότητας των προσωπικών σας πληροφοριών. Όταν
        χρησιμοποιείτε τον ιστότοπο και τις υπηρεσίες μας, συλλέγουμε, χρησιμοποιούμε και αποκαλύπτουμε πληροφορίες όπως
        περιγράφεται στην παρούσα Πολιτική Απορρήτου. Παρακαλούμε αφιερώστε λίγο χρόνο για να τη διαβάσετε και
        επικοινωνήστε μαζί μας αν έχετε ερωτήσεις ή απορίες.
      </p>

      <h2>Πληροφορίες που Συλλέγουμε</h2>
      <p>Μπορεί να συλλέξουμε διάφορους τύπους πληροφοριών από εσάς όταν αλληλεπιδράτε με τον ιστότοπό μας:</p>

      <h3>1. Προσωπικές Πληροφορίες</h3>
      <p>
        Όταν μας παρέχετε εθελοντικά προσωπικές πληροφορίες μέσω φορμών ή άλλων καναλιών επικοινωνίας, μπορεί να τις
        συλλέξουμε, συμπεριλαμβανομένων του ονόματος, της διεύθυνσης email και των στοιχείων επικοινωνίας σας. Αυτές οι
        πληροφορίες χρησιμοποιούνται για να καλύψουμε τις ανάγκες σας, να απαντήσουμε στις ερωτήσεις σας και να σας
        παρέχουμε την καλύτερη δυνατή εξυπηρέτηση.
      </p>

      <h3>2. Μη Προσωπικές Πληροφορίες</h3>
      <p>
        Μπορεί επίσης να συλλέξουμε μη προσωπικά δεδομένα, όπως η διεύθυνση IP σας, το λειτουργικό σας σύστημα, ο τύπος
        του προγράμματος περιήγησης και στατιστικά χρήσης του ιστότοπου. Αυτά τα δεδομένα χρησιμοποιούνται για εσωτερική
        ανάλυση τάσεων, διαχείριση του ιστότοπου, παρακολούθηση της κίνησης χρηστών και συλλογή δημογραφικών στοιχείων.
        Βοηθούν στη βελτίωση της λειτουργικότητας και της απόδοσης του ιστότοπού μας.
      </p>

      <h2>Πώς Χρησιμοποιούμε τις Πληροφορίες Σας</h2>
      <p>Χρησιμοποιούμε τα δεδομένα που συλλέγουμε για τους εξής σκοπούς:</p>
      <ul>
        <li>Παροχή και διατήρηση των υπηρεσιών μας.</li>
        <li>Βελτίωση, προσαρμογή και διεύρυνση των προσφορών μας.</li>
        <li>Απάντηση στις ερωτήσεις, τα σχόλια και τα αιτήματά σας.</li>
        <li>Επικοινωνία για προϊόντα, υπηρεσίες, προσφορές και εκπτώσεις.</li>
        <li>Παρακολούθηση και αξιολόγηση τάσεων, εφαρμογών και δράσεων.</li>
        <li>Πρόληψη, ανίχνευση και διερεύνηση τυχόν δόλιας ή παράνομης δραστηριότητας.</li>
        <li>Διασφάλιση της ακεραιότητας και της ασφάλειας του ιστότοπού μας.</li>
        <li>Συμμόρφωση με τις νομικές σας υποχρεώσεις.</li>
      </ul>

      <h2>Κοινοποίηση και Αποκάλυψη Πληροφοριών</h2>
      <p>
        Σεβόμαστε την ιδιωτικότητά σας και δεν πουλάμε ή αποκαλύπτουμε τα προσωπικά σας στοιχεία σε τρίτους, εκτός στις
        ακόλουθες περιπτώσεις:
      </p>
      <ul>
        <li>
          <strong>Πάροχοι Υπηρεσιών:</strong> Μπορεί να συνεργαστούμε με αξιόπιστους τρίτους για να εκτελέσουν εργασίες
          και να διαχειριστούν τις πληροφορίες σας για λογαριασμό μας. Αυτοί οι πάροχοι υποχρεούνται να διατηρούν τις
          πληροφορίες σας εμπιστευτικές και να τις χρησιμοποιούν μόνο για εκτέλεση εργασιών για εμάς.
        </li>
        <li>
          <strong>Νομικές Απαιτήσεις:</strong> Εάν απαιτείται από το νόμο ή σε απάντηση νόμιμων αιτημάτων από δημόσιες
          αρχές (π.χ. δικαστήριο ή κυβερνητική υπηρεσία), μπορεί να αποκαλύψουμε τις πληροφορίες σας.
        </li>
        <li>
          <strong>Επιχειρηματικές Μεταβιβάσεις:</strong> Σε περίπτωση συγχώνευσης, εξαγοράς ή πώλησης των περιουσιακών
          μας στοιχείων, οι πληροφορίες σας μπορεί να μεταφερθούν στην εξαγοράζουσα εταιρεία.
        </li>
        <li>
          <strong>Συναίνεση:</strong> Μπορείτε να μας δώσετε την άδειά σας να μοιραστούμε τις πληροφορίες σας ή να το
          ζητήσετε εσείς.
        </li>
      </ul>

      <h2>Διατήρηση Δεδομένων</h2>
      <p>
        Θα διατηρήσουμε τα προσωπικά σας στοιχεία για όσο χρόνο είναι απαραίτητο για την επίτευξη των στόχων που
        καθορίζονται στην παρούσα πολιτική απορρήτου, εκτός εάν απαιτείται ή επιτρέπεται μεγαλύτερη περίοδος διατήρησης
        από το νόμο.
      </p>

      <h2>Τα Δικαιώματά Σας</h2>
      <p>Έχετε το δικαίωμα να:</p>
      <ul>
        <li>Προβείτε σε ανάκτηση, διόρθωση ή διαγραφή των προσωπικών σας δεδομένων.</li>
        <li>Ανακαλέσετε τη συγκατάθεσή σας οποιαδήποτε στιγμή.</li>
        <li>Αρνηθείτε την επεξεργασία των προσωπικών σας δεδομένων.</li>
        <li>Λάβετε αντίγραφο των δεδομένων που έχουμε για εσάς.</li>
        <li>Περιορίσετε ή ελαχιστοποιήσετε τη χρήση των προσωπικών σας δεδομένων από εμάς.</li>
      </ul>

      <p>
        Επικοινωνήστε μαζί μας χρησιμοποιώντας τα στοιχεία επικοινωνίας στο τέλος αυτής της πολιτικής απορρήτου για να
        ασκήσετε οποιοδήποτε από αυτά τα δικαιώματα.
      </p>

      <h2>Ασφάλεια</h2>
      <p>
        Λαμβάνουμε κατάλληλες προφυλάξεις για να προστατεύσουμε τα προσωπικά σας δεδομένα από μη εξουσιοδοτημένη πρόσβαση,
        χρήση ή αποκάλυψη. Ωστόσο, σημειώστε ότι δεν μπορούμε να εγγυηθούμε πλήρη ασφάλεια και ότι καμία μέθοδος μετάδοσης
        δεδομένων μέσω Διαδικτύου ή αποθήκευσης ηλεκτρονικών αρχείων δεν είναι 100% ασφαλής.
      </p>

      <h2>Σύνδεσμοι Τρίτων</h2>
      <p>
        Ο ιστότοπός μας μπορεί να περιέχει συνδέσμους προς εξωτερικούς ιστότοπους ή υπηρεσίες που δεν λειτουργούν από εμάς.
        Δεν ελέγχουμε και δεν αναλαμβάνουμε καμία ευθύνη για τις πρακτικές απορρήτου, το περιεχόμενο ή τις πολιτικές οποιουδήποτε
        ιστότοπου τρίτων που συνδέεστε από τον ιστότοπό μας.
      </p>

      <h2>Αλλαγές στην Πολιτική Απορρήτου</h2>
      <p>
        Μπορεί να ενημερώνουμε την πολιτική απορρήτου μας κατά καιρούς. Οποιεσδήποτε ενημερώσεις θα εμφανίζονται σε αυτή
        τη σελίδα, μαζί με μια αναθεώρηση της ημερομηνίας "Τελευταία Ενημέρωση". Συνιστούμε να ελέγχετε αυτήν την πολιτική
        τακτικά για τυχόν αλλαγές.
      </p>
    </div>
  );
}

export default PrivacyBlack;
