import React from 'react';

function CookieBlack() {
  return (
    <main className="flex flex-col w-full max-w-[90%] container justify-center gap-2">
      <h1 className="font-bold text-4xl">Πολιτική για τα Cookies</h1>
      <h2 className="font-medium text-2xl">Εισαγωγή</h2>
      <p>
        Αυτή η Πολιτική για τα Cookies εξηγεί πώς <b>
          <script>
            const hostname = window.location.hostname;
            const name = hostname[0].toUpperCase() + hostname.slice(1, hostname.length);
            document.write(name);
          </script>
        </b> ("εμείς", "εμάς", και "μας") χρησιμοποιούμε cookies και παρόμοιες
        τεχνολογίες για να σας αναγνωρίσουμε όταν επισκέπτεστε τον ιστότοπό μας στο 
        <script>document.write(window.location.origin)</script>. Εξηγεί τι είναι αυτές οι τεχνολογίες, γιατί τις
        χρησιμοποιούμε και τα δικαιώματά σας να ελέγχετε τη χρήση τους από εμάς.
      </p>

      <h2 className="font-medium text-2xl">Τι είναι τα cookies;</h2>
      <p>
        Τα cookies είναι μικρά αρχεία δεδομένων που τοποθετούνται στον υπολογιστή ή την κινητή συσκευή σας όταν επισκέπτεστε
        έναν ιστότοπο. Τα cookies χρησιμοποιούνται ευρέως από ιδιοκτήτες ιστότοπων για να κάνουν τους ιστότοπούς τους να
        λειτουργούν ή να λειτουργούν πιο αποδοτικά, καθώς και για την παροχή πληροφοριών αναφοράς.
      </p>

      <p>
        Τα cookies που ορίζονται από τον ιδιοκτήτη του ιστότοπου (στην προκειμένη περίπτωση, <b>
          <script>document.write(name)</script>
        </b>) ονομάζονται "cookies πρώτου μέρους". Τα cookies που ορίζονται από μέρη εκτός του ιδιοκτήτη του ιστότοπου
        ονομάζονται "cookies τρίτου μέρους". Τα cookies τρίτου μέρους επιτρέπουν τη λειτουργία χαρακτηριστικών ή
        λειτουργιών τρίτων στον ιστότοπο (π.χ., διαφήμιση, διαδραστικό περιεχόμενο και αναλύσεις). 
      </p>

      <h2 className="font-medium text-2xl">Γιατί χρησιμοποιούμε cookies;</h2>
      <p>
        Χρησιμοποιούμε cookies πρώτου και τρίτου μέρους για διάφορους λόγους. Ορισμένα cookies είναι απαραίτητα για
        τεχνικούς λόγους προκειμένου να λειτουργήσουν οι Ιστότοποί μας και τα αποκαλούμε "απαραίτητα" cookies. Άλλα
        cookies μας επιτρέπουν να παρακολουθούμε και να στοχεύουμε τα ενδιαφέροντα των χρηστών μας για να βελτιώσουμε την
        εμπειρία σας στις Ιδιότητες Διαδικτύου μας. 
      </p>

      <p>Οι τύποι cookies που χρησιμοποιούμε στον Ιστότοπό μας:</p>
      <ul className="list-disc list-inside">
        <li>
          Απαραίτητα Cookies: Αυτά τα cookies είναι απολύτως απαραίτητα για να σας παρέχουμε υπηρεσίες μέσω των
          Ιστότοπών μας και για την πρόσβαση σε ασφαλείς περιοχές.
        </li>
        <li>
          Cookies Απόδοσης και Λειτουργικότητας: Αυτά τα cookies χρησιμοποιούνται για τη βελτίωση της απόδοσης και
          της λειτουργικότητας των Ιστότοπών μας, αλλά δεν είναι απαραίτητα για τη χρήση τους.
        </li>
        <li>
          Cookies Αναλύσεων και Προσαρμογής: Αυτά τα cookies συλλέγουν πληροφορίες για να μας βοηθήσουν να
          κατανοήσουμε πώς χρησιμοποιούνται οι Ιστότοποί μας ή την αποτελεσματικότητα των διαφημιστικών μας
          καμπανιών.
        </li>
        <li>
          Cookies Διαφήμισης: Αυτά τα cookies χρησιμοποιούνται για να κάνουν τα διαφημιστικά μηνύματα πιο σχετικά με
          εσάς.
        </li>
      </ul>

      <h2 className="font-medium text-2xl">Πώς μπορώ να ελέγξω τα cookies;</h2>
      <p>
        Έχετε το δικαίωμα να αποφασίσετε αν θα αποδεχτείτε ή θα απορρίψετε τα cookies. Μπορείτε να ασκήσετε τα
        δικαιώματά σας για τα cookies μέσω του Διαχειριστή Συναίνεσης Cookies. 
      </p>
      <p>
        Τα συγκεκριμένα βήματα διαφέρουν ανάλογα με τον περιηγητή σας, γι' αυτό παρακαλούμε ανατρέξτε στο μενού βοήθειας
        του περιηγητή σας για περισσότερες πληροφορίες.
      </p>

      <h2 className="font-medium text-2xl">Αλλαγές στην Πολιτική για τα Cookies</h2>
      <p>
        Ενδέχεται να ενημερώνουμε αυτήν την Πολιτική για τα Cookies από καιρό σε καιρό ώστε να αντικατοπτρίζονται
        αλλαγές στα cookies που χρησιμοποιούμε ή για άλλους επιχειρησιακούς, νομικούς ή κανονιστικούς λόγους. 
        Παρακαλούμε να επισκέπτεστε αυτήν την Πολιτική τακτικά για να παραμένετε ενήμεροι.
      </p>

      <h2 className="font-medium text-2xl">Επικοινωνήστε μαζί μας</h2>
      <p>Αν έχετε οποιεσδήποτε ερωτήσεις σχετικά με τη χρήση των cookies, παρακαλούμε στείλτε μας email.</p>
    </main>
  );
}

export default CookieBlack;
